$default: #228dae;
$d-shade-2: #0d3541;
$d-shade-1: #1e7a97;
$d-tint: #ebf6f9;
$secondary: #f2d555;
$s-shade-1: #efc921;
$grey1: #333333;
$grey2: #4f4f4f;
$grey3: #828282;
$red: red;
