// Products
.products {
  margin: 80px 0px;

  .type-1-grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 600px) {
      grid-gap: 20px;
      grid-template-columns: minmax(1px, 1fr);
    }

    .type-1 {
      img {
        object-fit: contain;
      }
    }
  }

  .type-2-grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: auto auto;
    grid-template-areas:
      "full full"
      "half1 half2"
      "half3 .";

    @media (max-width: 600px) {
      grid-gap: 20px;
      grid-template-columns: minmax(1px, 1fr);
      grid-template-areas:
        "full"
        "half1"
        "half2"
        "half3";
    }

    div {
      img {
        object-fit: contain;
      }
    }

    .item-0 {
      grid-area: full;
      img {
        height: 500px;

        @media (max-width: 600px) {
          height: 300px;
        }
      }
    }

    .item-1 {
      grid-area: half1;
      img {
        height: 300px;
        @media (max-width: 600px) {
          height: 200px;
        }
      }
    }
    .item-2 {
      grid-area: half2;
      img {
        height: 300px;
        @media (max-width: 600px) {
          height: 200px;
        }
      }
    }
    .item-3 {
      grid-area: half3;
      img {
        width: 286px;
        height: 400px;
        object-fit: cover;

        @media (max-width: 600px) {
          width: auto;
        }
      }
    }
  }

  .type-3-grid {
    display: flex;
    margin: 0px -10px;
    flex-wrap: wrap;
    justify-content: center;

    .col {
      padding: 0px 10px;
      width: 40%;
      margin-bottom: 20px;
      img {
        object-fit: contain;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
    }

    .item-0,
    .item-1,
    .item-2,
    .item-3 {
      img {
        height: 500px;
        @media (max-width: 600px) {
          height: 300px;
        }
      }
    }

    .item-4,
    .item-5 {
      img {
        height: 300px;
      }
    }
  }

  .type-4-grid {
    display: flex;
    margin: 0px -20px;
    flex-wrap: wrap;
    justify-content: center;

    .col {
      padding: 0px 20px;
      width: 50%;
      margin-bottom: 40px;
      img {
        height: 350px;
        object-fit: contain;
      }

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    .item-2 {
      width: 100%;
      img {
        height: 400px;
        @media (max-width: 600px) {
          height: 200px;
        }
      }
    }
  }

  .type-5-grid {
    display: flex;
    margin: 0px -20px;
    flex-wrap: wrap;
    justify-content: center;

    .col {
      padding: 0px 20px;
      width: 60%;
      margin-bottom: 40px;
      img {
        height: 800px;
      }

      @media (max-width: 600px) {
        width: 100%;
        img {
          height: 300px;
        }
      }
    }
  }
}

// About
.page-about {
  margin: 88px auto;

  @media (max-width: 600px) {
    font-size: 14px;
  }

  section {
    margin: 0px 40px;
    h3 {
      margin-bottom: 0.5em;
    }
    margin-bottom: 40px;
  }
}
