nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  position: fixed;
  color: $grey1;
  background: rgba(245, 242, 242, 1);
  box-shadow: 0px 4px 24px rgba(147, 139, 139, 0.2);
  z-index: 99;
  transition: 0.3s ease-in-out;
  top: 0;

  @media (max-width: 1024px) {
    padding: 0px 24px;
  }

  &.scroll {
    position: absolute;
    color: #fff;
    background: rgba(245, 242, 242, 0);
    box-shadow: none;

    .link-underline {
      background: #fff !important;
    }

    @media (max-width: 1048px) {
      &-open {
        color: $grey1;
        background: #fff;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    padding: 0 64px;

    img {
      height: 80px;
      margin-right: 8px;
      object-fit: contain;
    }

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 21px;
      padding: 0;

      img {
        height: 48px;
        margin-right: 8px;
      }
    }
  }

  .hamburger {
    display: none;
    padding: 2px 8px;
    background: #fff;
    border-radius: 2px;
    cursor: pointer;

    @media (max-width: 1048px) {
      display: block;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    column-gap: 24px;
    padding: 16px 64px;

    @media (max-width: 1048px) {
      display: none;
      position: absolute;
      width: 100vw;
      left: 0;
      background: #fff;
      top: 48px;
      z-index: 9;
      height: 100vh;
      padding-top: 32px;
      padding: 32px 16px;
      border-top: 1px solid #e6e6e6;
      flex-direction: column;

      &.open {
        display: block;
      }
    }

    .link.active {
      color: $d-shade-1;
      font-weight: bold;
    }

    .link-underline {
      width: 0;
      height: 3px;
      background: $d-shade-1;
      border-radius: 2px;
      transition: width 250ms ease-in-out;
      position: absolute;
      bottom: 4px;
    }

    .link {
      font-style: normal;
      position: relative;
      font-size: 16px;
      line-height: 19px;
      padding: 12px;

      @media (max-width: 1024px) {
        margin-bottom: 24px;
        max-width: max-content;
      }

      &:hover .link-underline,
      &.active .link-underline {
        width: calc(100% - 24px);
      }
    }
  }
}

.section-colums {
  background: #333333;
  color: #fff;

  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .contact-content {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 3fr 1fr;
    padding: 0px 32px;
    margin-top: 32px;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      margin-top: 0;
      grid-gap: 24px;
    }
  }

  .contact-eng {
    display: flex;
    column-gap: 60px;
    flex-wrap: wrap;

    & > div {
      flex: 1;

      @media (max-width: 768px) {
        margin-bottom: 32px;
      }
    }
  }

  .name {
    margin-bottom: 4px;
    font-weight: 700;
  }
}

.contact {
  padding: 80px 0;
  font-size: 12px;
  @media (max-width: 600px) {
    padding: 32px 0;
  }
}

.footer {
  border-top: 1px solid #f5f5f5;
  background: #fff;
  padding: 40px 0;
  color: #000;
  font-size: 14px;
  padding-bottom: 64px;

  .menu-title {
    font-weight: 700;
    margin-bottom: 16px;
    font-size: 16px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .footer-link {
    line-height: 24px;

    &:hover {
      text-decoration: underline;
    }
  }

  .divider {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 16px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .footer-column {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  .footer-row {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
  }

  .icon {
    svg {
      fill: $default;
    }
  }
}

.copyright {
  background: #1e7a97;
  text-align: center;
  padding: 16px;
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.header {
  margin-top: 80px;
  overflow: hidden;
  background-image: url("../img/overlay.png");
  background-repeat: no-repeat;
  background-size: cover;

  .heading {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 152px;
    align-items: center;
  }

  .back {
    margin-top: 8px;

    .icon {
      top: 3px;
      margin-right: 4px;
      position: relative;
      svg {
        fill: $grey2;
      }
    }

    a {
      font-size: 14px;
      color: #000;
      font-weight: 400;
      color: $grey2;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 600px) {
    font-size: 20px;
    margin: auto;
    text-align: center;
    margin-top: 64px;
    height: 120px;
  }
}

/* Accordion styles */
.tabs {
  border-radius: 8px;
  // overflow: hidden;
  // box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}
.tab {
  width: 100%;
  overflow: hidden;
  margin-bottom: 32px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px 6px rgba(26, 22, 44, 0.1);
  border-radius: 8px;

  &-label {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;

    /* Icon */
    &:hover {
      color: $red;
    }
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: ease-in-out 0.35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 24px;
    background: white;
    transition: ease-in-out 0.35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
  }
}

// :checked
input:checked {
  + .tab-label {
    color: $red;

    // background: darken($red, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100%;
    height: 100%;
    padding: 24px;

    .divider {
      width: 100%;
      border-top: 1px solid #bdbdbd;
      transform: translateY(-24px);
    }
  }
}

.icon {
  height: 16px;
  width: 16px;

  // display: inline-block;
  svg {
    height: 16px;
    width: 16px;
    fill: #000;
  }
}

.sw-wrapper {
  margin: 0px -40px;

  @media (max-width: 600px) {
    margin: 0px 16px;
  }
}

.content-loader {
  background-color: #fff;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
}

.content-loader:only-child {
  margin-top: 0;
}

.content-loader:after {
  content: "";
  background-color: #333;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: loader-animate;
  animation-timing-function: linear;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
}

.content-loader:before {
  content: "";
  height: 100%;
  display: block;
  background-color: #d6d6d6;
  box-shadow: -48px 78px 0 -48px #d6d6d6, -51px 102px 0 -51px #d6d6d6;
}

@keyframes loader-animate {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.google-maps {
  margin-top: 8px;
  font-weight: 700;
  font-size: 14px;

  a {
    text-decoration: underline;
  }
}

.img-container {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding-bottom: 50%;

  @media (max-width: 600px) {
    padding-bottom: 67%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
  }
}
