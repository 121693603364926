.swiper-button-next,
.swiper-button-prev {
  &::after {
    display: none;
  }

  background: #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 4px 24px rgba(147, 139, 139, 0.2);
  transition: all 0.2s ease;

  svg {
    fill: #000;
  }

  &:hover {
    background: $default;

    svg {
      fill: #fff;
    }
  }
}

.swiper-button-prev {
  left: 40px;
  @media (max-width: 600px) {
    left: 16px;
  }

  &.testi-carousel-prev {
    left: 24px;

    @media (max-width: 600px) {
      display: none;
    }
  }
}

.swiper-button-next {
  right: 40px;

  @media (max-width: 600px) {
    right: 16px;
  }

  &.testi-carousel-next {
    right: 24px;

    @media (max-width: 600px) {
      display: none;
    }
  }
}

.swiper-pagination-bullet {
  border-radius: 8px;
  width: 40px;
  height: 4px;
}

.swiper-pagination-bullets {
  text-align: center;
}

.swiper-pagination-bullet-active {
  background: $default;
}
