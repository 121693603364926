// Home
.hero {
  position: relative;

  // &.zoom {
  //   animation: zoomin 15s ease-in infinite;
  //   transition: all 0.5s ease-in-out;
  //   overflow: hidden;
  // }
}

.brand {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  top: 50%;
  transform: translate(0px, -50%);
  color: #000;

  @media (max-width: 600px) {
    bottom: 0px;
    transform: none;
  }

  .hero-box {
    background-color: rgba(235, 246, 249, 0.9);
    padding: 56px 32px;
    max-width: 45%;

    @media (max-width: 768px) {
      max-width: 100%;
      padding: 16px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 32px;

    @media (max-width: 600px) {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .motto {
    font-weight: normal;
    font-size: 16px;
    margin-top: 16px;
    max-width: 80%;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .hero-button {
    margin-top: 40px;

    @media (max-width: 600px) {
      margin-top: 24px;
    }
  }
}

.section-intro {
  background-image: url("../img/overlay.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0;

  .flex {
    display: flex;
    margin: 0px -16px;
    flex-wrap: wrap;
    align-items: center;

    .col {
      width: 50%;
      padding: 0px 16px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .desc {
    font-size: 16px;
    max-width: 70%;
    line-height: 1.8;

    &.proyek {
      max-width: 100%;
      margin-bottom: 32px;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .content {
    order: 1;

    @media (max-width: 768px) {
      margin-top: 40px;
      order: 2;
    }
  }

  .image {
    text-align: right;
    order: 2;

    @media (max-width: 768px) {
      text-align: center;
      order: 1;
    }

    img {
      width: 480px;
      max-height: 540px;
      object-fit: cover;
      box-shadow: 0px 4px 10px rgba(33, 68, 87, 0.2);

      @media (max-width: 768px) {
        width: 320px;
        max-height: 400px;
      }

      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 40px 0;

    .desc {
      max-width: 100%;
    }
  }
}

.section-title {
  font-size: 32px;
  margin-bottom: 24px;

  span {
    color: $default;
    font-weight: 800;
    margin-left: 8px;
  }

  .subtitle {
    color: #4f4f4f;
    font-size: 22px;

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  @media (max-width: 600px) {
    font-size: 20px;
    font-weight: 400;
  }
}

.lp-bg {
  background-color: #f9f7f6;
  padding: 80px 0px;

  @media (max-width: 600px) {
    padding: 40px 0px;
  }
}

.section-latest-project {
  margin: 0 -16px;
  display: flex;
  flex-wrap: wrap;

  & > div {
    padding: 0px 16px;
    width: 50%;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .lp-facility {
    font-size: 14px;

    .section-title span {
      margin-left: 0;
    }

    .lp-desc {
      font-size: 16px;
      width: 90%;

      @media (max-width: 1024px) {
        width: 100%;
        font-size: 14px;
      }
    }

    .lp-loc {
      .label {
        margin-top: 24px;
        font-weight: 800;
      }
    }
    .lp-divider {
      margin: 32px 0px;
      width: 80%;
      height: 2px;
      border-top: 1px solid #e0e0e0;
    }
  }

  .lp-image-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "img img"
      "img2 img3";
    grid-template-rows: 1fr 1.1fr;
    grid-gap: 24px;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;

    @media (max-width: 1024px) {
      height: 80vh;
      margin-top: 32px;
    }

    @media (max-width: 600px) {
      height: 500px;
      margin-top: 32px;
    }

    &.show {
      opacity: 1;
    }
    .lp-grid-1 {
      grid-area: img;
    }
    .lp-grid-2 {
      grid-area: img2;
    }
    .lp-grid-3 {
      grid-area: img3;
    }
  }
}

.lp-loader {
  .lp-loader-title {
    width: 80%;
    height: 100px;
    margin-bottom: 16px;
  }

  .lp-loader-content {
    width: 80%;
    height: 300px;
  }

  .lp-loader-foto {
    width: 100%;
    height: 100%;
  }
}

.section-best {
  padding: 80px 0px;
  @media (max-width: 600px) {
    padding: 40px 0px;
  }

  .b-desc {
    max-width: 60%;

    @media (max-width: 600px) {
      max-width: 100%;
      font-size: 14px;
    }
  }

  .b-step {
    color: $d-shade-1;
    font-size: 24px;
    margin: 40px 0px 16px;
  }

  .b-card {
    display: flex;
    margin: 0px -16px;
    flex-wrap: wrap;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .b-card-content {
      padding: 40px 48px;
      background: #fafbf8;
      border: 1px solid #e0e0e0;
      height: 100%;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 600px) {
        margin-bottom: 24px;
      }

      .icon {
        height: 40px;
        width: 40px;
        margin-bottom: 16px;

        svg {
          height: 40px;
          width: 40px;

          fill: $default;
        }
      }

      .count {
        font-size: 32px;
      }

      .label {
        font-size: 18px;
        color: #4f4f4f;
      }

      .note {
        font-size: 14px;
        margin-top: 32px;
      }

      .testi-link {
        display: block;
        margin-top: 16px;
        color: $default;
        font-weight: 800;
      }
    }

    & > div {
      padding: 0px 16px;
      min-height: 200px;

      &:first-child {
        flex: 1;

        .b-card-content {
          background: #ebf6f9;
          box-shadow: 0px 4px 24px rgba(147, 139, 139, 0.2);
        }

        .label {
          font-weight: 700;
          color: #000;
        }

        .icon {
          height: 32px;
          width: 32px;
          margin-bottom: 8px;

          svg {
            height: 32px;
            width: 32px;

            fill: $default;
          }
        }
      }
    }
  }
}

.section-testimoni {
  padding: 40px 0px 80px;

  .swiper-slide {
    height: auto;
  }

  .t-slide-wrapper {
    padding: 54px 40px 16px;
    height: 100%;

    @media (max-width: 600px) {
      padding: 54px 0px 16px;
    }
  }

  .t-slide-box {
    height: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding: 32px;
    background-color: #fdfbf3;
    // border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    img {
      height: 100px;
      width: 100px;
      object-fit: cover;
      position: absolute;
      left: 32px;
      top: -40px;
      border-radius: 4px;
    }

    .testi {
      padding-top: 48px;
    }

    .nama {
      font-weight: 800;
      margin-top: 16px;
    }
  }
}

.section-offer {
  text-align: center;
  position: relative;

  .section-title {
    font-weight: 800;
    font-size: 24px;

    @media (max-width: 600px) {
      font-size: 20px;
    }
  }
}

.section-interior {
  .lp-interior-image {
    width: 40%;
    text-align: right;
    img {
      width: 400px;
      object-fit: cover;
    }

    @media (max-width: 600px) {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  .section-latest-project {
    .lp-facility {
      width: 60%;

      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }

  .button-koleksi {
    width: 400px;
    margin-top: 16px;
    text-align: center;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .interior-produk {
    display: flex;
    @media (max-width: 600px) {
      margin-bottom: 24px;
    }

    .title {
      font-size: 18px;
      font-weight: 800;
      margin-top: 8px;

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }

    .sub {
      color: #828282;
      @media (max-width: 600px) {
        font-size: 12px;
      }
    }

    .room {
      margin-right: 64px;

      @media (max-width: 600px) {
        margin-right: 24px;
      }
    }

    .icon svg {
      width: 38px;
      height: 38px;
      fill: $d-shade-1;
    }
  }
}
/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
} /*End of Zoom in Keyframes */
