.button {
  box-shadow: 0px 4px 10px rgba(33, 68, 87, 0.2);
  padding: 16px 40px;
  border-radius: 0;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.04em;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-block;

  &.primary {
    background-color: $default;
    color: #fff;

    &:hover {
      background-color: $d-shade-1;
    }
  }

  &.secondary {
    background-color: $secondary;
    color: #000;

    &:hover {
      background-color: $s-shade-1;
    }
  }

  &.outlined {
    background-color: #fff;
    border: 2px solid $default;
    color: #000;

    &:hover {
      background-color: $d-tint;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
}

.icon-link {
  &.primary {
    .icon svg {
      fill: #fff !important;
    }
  }
}

.white-overlay {
  position: absolute;
  height: 60px;
  width: 100%;
  bottom: 0;
  background: linear-gradient(0deg, #ffffff 20%, rgba(255, 255, 255, 0) 100%);
}

.icon-group {
  margin-bottom: 32px;
  .icon {
    top: 4px;
    position: relative;
    margin-right: 16px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.donut {
  width: 3rem;
  height: 3rem;
  margin: 3rem;
  border-radius: 50%;
  border: 0.4rem solid rgba($default, 0.3);
  border-top-color: $default;
  animation: 1.5s spin infinite linear;

  &.multi {
    border-bottom-color: $default;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  background: $d-tint;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.konten-wrapper {
  padding: 88px 0px;

  @media (max-width: 768px) {
    padding: 32px 0px;
  }
}

.kontak {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -16px;

  & > div {
    width: 33%;
    padding: 0px 16px;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  .content-loader {
    height: 300px;
  }

  .box {
    text-align: center;
    padding: 24px;
    box-shadow: 0px 4px 24px rgba(147, 139, 139, 0.2);
    font-size: 14px;
    color: #8d8d8d;
  }

  .name {
    font-size: 16px;
    color: #000;
    font-weight: 800;
    margin-bottom: 16px;
    // margin-top: 8px;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
